import React, { useEffect, useState } from 'react';
import img from './hover-off-min.png'
import video from './alley.mp4'
import "./style.scss"
const Modal = () => {
    const [check, setCheck] = useState(false)

    const closeModal = () => {
        // setModal(false)
        const header = document.querySelector('.header__navigation-container')
        const modalContianer = document.querySelector('.modal-container')
        modalContianer.classList.remove('active')
    }

    useEffect(() => {
        const mainContainer = document.querySelector('.modal-container .photo');
        resize();
        // document.getElementById('myVideo').play();
        window.onresize = resize
        function resize() {
            if ((window.innerWidth / window.innerHeight) > (1920 / 1080)) {
                mainContainer.style.width = `${window.innerWidth}px`
                mainContainer.style.height = `${window.innerWidth / (1920 / 1080)}px`
            } else {
                mainContainer.style.width = `${window.innerHeight * (1920 / 1080)}px`
                mainContainer.style.height = `${window.innerHeight}px`
            }
        }
    }, [])

    return (
        <>
            <div className='modal-container'  >
                {/* <div className="modal-lightning-hover">

            </div> */}

                <div className="modal-window">
                    <div className="modal">
                        <span className="modal-cross" onClick={closeModal}>
                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 94.926 94.926" style={{ enableBackground: 'new 0 0 94.926 94.926' }}
                                xmlSpace="preserve">
                                <g>
                                    <path d="M55.931,47.463L94.306,9.09c0.826-0.827,0.826-2.167,0-2.994L88.833,0.62C88.436,0.224,87.896,0,87.335,0
                                    c-0.562,0-1.101,0.224-1.498,0.62L47.463,38.994L9.089,0.62c-0.795-0.795-2.202-0.794-2.995,0L0.622,6.096
                                    c-0.827,0.827-0.827,2.167,0,2.994l38.374,38.373L0.622,85.836c-0.827,0.827-0.827,2.167,0,2.994l5.473,5.476
                                    c0.397,0.396,0.936,0.62,1.498,0.62s1.1-0.224,1.497-0.62l38.374-38.374l38.374,38.374c0.397,0.396,0.937,0.62,1.498,0.62
                                    s1.101-0.224,1.498-0.62l5.473-5.476c0.826-0.827,0.826-2.167,0-2.994L55.931,47.463z"/>
                                </g>

                            </svg>
                        </span>
                        <article>
                            <h2 className="modal-title">
                                Before you continue...
                            </h2>
                            <div className="" style={{ paddingTop: '15px' }}>
                                <p className="modal-paragraph">
                                    Please read the rules carefully. Please make sure you have $WSBT tokens on your account in order to use the platform. You can swap tokens on any of the supported markets.
                                </p>
                            </div>
                        </article>
                        <div className="modal__bottom">
                            <div className="modal__line">
                                <span className="line"></span>
                            </div>
                            <ul className="modal__markets --horizontal">
                                <li className="header__navigation-item">
                                    <a href="https://www.mexc.com/exchange/WSBT_USDT" className="header__navigation-link " target="_blank" >
                                        <span className="svg-link" style={{ background: '' }} >
                                            <svg width="37" height="37" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 40 31" style={{ enableBackground: 'new 0 0 40 31' }} xmlSpace="preserve">
                                                <path fill='#C1C1C1' d="M39.3,22.8l-8.6-15c-1.9-3.1-6.5-3.2-8.3,0.1l-9.1,15.6c-1.7,2.9,0.4,6.4,3.8,6.4h18.1  C38.6,29.9,41.2,26.3,39.3,22.8z" />
                                                <path fill='#ffffff' d="M26.9,23.9L26.3,23c-0.5-0.9-1.6-2.7-1.6-2.7L17.4,7.6C15.5,4.8,11,4.5,9.1,8.1L0.7,22.8  c-1.8,3.1,0.3,7.1,4.1,7.1h18h7.7h4.6C30.5,30,29,27.5,26.9,23.9z" />
                                            </svg>
                                        </span>
                                        {/* MeXC Global */}
                                    </a>


                                </li>
                                <li className="header__navigation-item">
                                    <a href="https://www.hotbit.io/exchange?symbol=WSBT_USDT" className="header__navigation-link " target="_blank" >
                                        <span className="svg-link" style={{ background: '' }} >
                                            <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M0.259583 10.5478V26.0996L18.5416 36.6651L36.3488 26.0996V10.5478L18.5416 0.101074L0.259583 10.5478ZM3.93973 23.844V17.7896L20.5597 27.168L24.1212 24.7937L18.5416 20.9949L21.6067 17.2176L32.9189 23.7033L18.5416 32.1874L3.93973 23.844ZM18.0245 15.2946L14.7817 19.2161L3.93973 12.8059L18.5416 4.5103L32.9189 12.919V18.9144L15.6867 9.2237L11.8028 11.5993L18.0245 15.2946Z" fill="#FFFFFF" />
                                            </svg>
                                        </span>
                                        {/* Hotbit */}
                                    </a>


                                </li>
                                <li className="header__navigation-item">
                                    <a href="https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0x7f4e04aa61b9a46403c1634e91bf31df3bc554cf" className="header__navigation-link " target="_blank" >
                                        <span className="svg-link" style={{ background: '' }} >
                                            <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M7.07567 6.19029C6.50007 3.31226 8.80249 0.578125 11.6805 0.578125C14.2708 0.578125 16.4293 2.73665 16.4293 5.32688V11.0829C17.1488 11.0829 17.7244 11.0829 18.4439 11.0829C19.0195 11.0829 19.739 11.0829 20.3146 11.0829V5.32688C20.3146 2.73665 22.4731 0.578125 25.0634 0.578125C27.9414 0.578125 30.2438 3.31226 29.6682 6.19029L28.3731 12.9537C32.6902 14.8244 35.9999 18.1341 35.9999 22.3073V24.7536C35.9999 28.2072 33.6975 31.0853 30.5316 32.956C27.3658 34.9706 23.0488 36.1218 18.3 36.1218C13.5512 36.1218 9.2342 34.9706 6.06836 32.956C2.90252 31.0853 0.600098 28.2072 0.600098 24.7536V22.3073C0.600098 18.1341 3.90983 14.8244 8.22688 12.9537L7.07567 6.19029ZM26.7902 13.961L28.2292 6.04639C28.6609 4.03176 27.078 2.16104 25.0634 2.16104C23.1927 2.16104 21.7536 3.60006 21.7536 5.47078V12.8098C21.3219 12.8098 20.7463 12.6659 20.3146 12.6659C19.739 12.6659 19.0195 12.6659 18.4439 12.6659C17.7244 12.6659 17.1488 12.6659 16.4293 12.8098C15.9976 12.8098 15.422 12.9537 14.9903 12.9537V5.61468C14.9903 3.74396 13.5512 2.30495 11.6805 2.30495C9.6659 2.30495 8.08298 4.17567 8.51469 6.19029L9.9537 14.2488C5.34885 15.9756 2.18302 19.1414 2.18302 22.739V25.1853C2.18302 30.6536 9.3781 35.1145 18.3 35.1145C27.2219 35.1145 34.417 30.6536 34.417 25.1853V22.739C34.5609 18.8536 31.3951 15.6878 26.7902 13.961Z" fill="#ffffff" />
                                                <path d="M34.5609 24.898C34.5609 30.3663 27.3658 34.8272 18.4439 34.8272C9.52199 34.8272 2.3269 30.3663 2.3269 24.898V22.4517H34.7048V24.898H34.5609Z" fill="#C1C1C1" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M8.51482 5.90292C8.08311 3.8883 9.66603 2.01758 11.6807 2.01758C13.5514 2.01758 14.9904 3.45659 14.9904 5.32731V12.8102C16.1416 12.6663 17.2928 12.5224 18.444 12.5224C19.5952 12.5224 20.7465 12.6663 21.7538 12.6663V5.32731C21.7538 3.45659 23.1928 2.01758 25.0635 2.01758C27.0781 2.01758 28.661 3.8883 28.2293 5.90292L26.7903 13.8175C31.5391 15.5443 34.7049 18.7102 34.7049 22.3077C34.7049 27.776 27.5098 32.2369 18.5879 32.2369C9.66603 32.2369 2.47095 27.776 2.47095 22.3077C2.47095 18.7102 5.63678 15.5443 10.2416 13.8175L8.51482 5.90292Z" fill="#ffffff" />
                                                <path d="M13.9827 21.7312C13.9827 23.1702 13.1193 24.4653 12.2559 24.4653C11.3925 24.4653 10.5291 23.3141 10.5291 21.7312C10.5291 20.2922 11.3925 18.9971 12.2559 18.9971C13.1193 18.9971 13.9827 20.1483 13.9827 21.7312Z" fill="#C1C1C1" />
                                                <path d="M26.3584 21.7312C26.3584 23.1702 25.495 24.4653 24.6316 24.4653C23.6243 24.4653 22.9048 23.3141 22.9048 21.7312C22.9048 20.2922 23.7682 18.9971 24.6316 18.9971C25.495 18.9971 26.3584 20.1483 26.3584 21.7312Z" fill="#C1C1C1" />
                                            </svg>
                                        </span>
                                        {/* PancakeSwap */}
                                    </a>


                                </li>
                                <li className="header__navigation-item" >
                                    <a href="https://app.uniswap.org/#/swap?chain=polygon&outputCurrency=0x7f4e04aa61b9a46403c1634e91bf31df3bc554cf" className="header__navigation-link " target="_blank" >
                                        <span className="svg-link" style={{ background: '' }} >
                                            <svg fill="none" width="37" height="37" viewBox="0 0 49 55" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                                <clipPath id="a">
                                                    <path d="m.994141 0h47.7206v55h-47.7206z" />
                                                </clipPath>
                                                <g clipPath="url(#a)" fill="#ffffff">
                                                    <path d="m19.7079 12.5152c-.5954-.0851-.6238-.1135-.3402-.1419.5387-.0851 1.7863.0284 2.6653.2271 2.0415.4824 3.8845 1.7311 5.841 3.9164l.5104.596.7372-.1136c3.1473-.5108 6.3798-.1135 9.0734 1.1352.7372.3406 1.8998 1.0217 2.0415 1.192.0568.0567.1418.4257.1985.7946.1985 1.3339.1135 2.3272-.3119 3.0934-.2268.4257-.2268.5392-.085.9082.1134.2838.4536.4824.7655.4824.6805 0 1.3894-1.0784 1.7297-2.5825l.1417-.596.2552.2838c1.4461 1.6176 2.5803 3.8596 2.7504 5.4489l.0567.4257-.2552-.3689c-.4253-.6528-.8222-1.0785-1.361-1.4474-.964-.6527-1.9848-.8514-4.6785-.9933-2.4385-.1419-3.8278-.3406-5.1888-.7946-2.3251-.7663-3.516-1.7596-6.2664-5.4206-1.2192-1.6176-1.9848-2.4974-2.7504-3.2353-1.6729-1.6176-3.3458-2.469-5.5291-2.8096z" />
                                                    <path d="m40.8315 16.1194c.0567-1.0784.1984-1.7879.5103-2.4407.1135-.2554.2269-.4824.2552-.4824.0284 0-.0283.1986-.1134.4257-.2268.6243-.2552 1.5041-.1134 2.4974.1985 1.2771.2835 1.4474 1.6446 2.838.6238.6527 1.361 1.4757 1.6445 1.8163l.4821.6243-.4821-.454c-.5954-.5676-1.9564-1.6461-2.2683-1.788-.1985-.1135-.2269-.1135-.3686.0284-.1135.1135-.1418.2838-.1418 1.1068-.0284 1.2771-.1985 2.0718-.6238 2.8948-.2269.4257-.2552.3405-.0567-.1419.1417-.369.1701-.5392.1701-1.7596 0-2.469-.2835-3.065-2.0132-4.0583-.4253-.2554-1.1625-.6243-1.5878-.823-.4537-.1987-.794-.3689-.7656-.3689.0567-.0568 1.7296.4257 2.3818.7095.9924.3973 1.1625.4257 1.2759.3973.0851-.0852.1418-.3122.1702-1.0217z" />
                                                    <path d="m20.8705 20.348c-1.1909-1.646-1.9565-4.2002-1.7864-6.1016l.0568-.596.2835.0568c.5104.0851 1.3894.4256 1.8147.6811 1.1342.6811 1.6445 1.6176 2.1266 3.9448.1417.6811.3402 1.4757.4253 1.7311.1418.4257.6805 1.419 1.1342 2.0434.3119.454.1134.6811-.5955.6243-1.0775-.1135-2.5235-1.1068-3.4592-2.3839z" />
                                                    <path d="m39.3861 32.6934c-5.6142-2.2703-7.599-4.2285-7.599-7.549 0-.4824.0284-.8798.0284-.8798.0283 0 .2268.1703.482.369 1.1342.9081 2.4101 1.3055 5.9544 1.8163 2.0699.3122 3.2608.5392 4.3383.9081 3.4308 1.1352 5.5574 3.4624 6.0678 6.6125.1418.9082.0567 2.6393-.1701 3.5475-.1985.7095-.7656 2.015-.9074 2.0433-.0283 0-.085-.1419-.085-.3689-.0567-1.1919-.6522-2.3271-1.6446-3.2069-1.1909-1.0217-2.722-1.7879-6.4648-3.2921z" />
                                                    <path d="m35.4166 33.6303c-.0568-.4257-.1985-.9649-.2836-1.1919l-.1418-.4257.2552.3122c.3686.4257.6522.9365.9074 1.646.1985.5392.1985.7095.1985 1.5893 0 .8514-.0284 1.05-.1985 1.5325-.2836.7662-.6238 1.3054-1.1909 1.9014-1.0208 1.0501-2.3534 1.6177-4.2532 1.8731-.3402.0284-1.3043.1135-2.1549.1703-2.1266.1135-3.5443.3405-4.8203.7946-.1701.0567-.3403.1135-.3686.0851-.0567-.0567.8223-.5676 1.5311-.9081.9924-.4825 2.0132-.7379 4.2532-1.1352 1.1058-.1703 2.24-.3973 2.5236-.5109 2.8071-.8797 4.1964-3.065 3.7428-5.7327z" />
                                                    <path d="m37.9961 38.1987c-.7372-1.6176-.9073-3.1502-.5104-4.5975.0568-.1419.1135-.2838.1702-.2838s.2268.0851.3969.1986c.3403.2271 1.0492.6244 2.8638 1.6177 2.2968 1.2487 3.6011 2.2136 4.5084 3.3204.7939.9649 1.276 2.0717 1.5028 3.434.1418.7662.0567 2.6109-.1418 3.3772-.6238 2.4122-2.0415 4.3421-4.1114 5.4489-.3119.1703-.5671.2838-.5954.2838-.0284 0 .085-.2838.2552-.6244.6805-1.4473.7655-2.8379.2552-4.3988-.3119-.9649-.9641-2.1285-2.2684-4.0867-1.5595-2.2704-1.9281-2.8664-2.3251-3.6894z" />
                                                    <path d="m16.8728 46.8828c2.0982-1.7596 4.6785-3.0083 7.0603-3.4056 1.0207-.1703 2.722-.1135 3.6577.1419 1.5028.3973 2.8638 1.2487 3.5726 2.2988.6806 1.0216.9925 1.9014 1.3044 3.8596.1134.7663.2551 1.5609.2835 1.7312.2268 1.0216.6805 1.8163 1.2476 2.242.879.6527 2.4101.6811 3.9129.1135.2552-.0852.482-.1703.482-.1419.0567.0567-.7088.5676-1.2192.823-.7089.3689-1.276.4824-2.0415.4824-1.361 0-2.5236-.7095-3.4593-2.1284-.1985-.2838-.5954-1.1068-.9357-1.8731-.9924-2.2987-1.5028-2.9799-2.6653-3.7461-1.0208-.6527-2.3251-.7946-3.3175-.3122-1.3043.6244-1.6445 2.2988-.7372 3.3204.3686.4257 1.0491.7663 1.6162.8514 1.0491.1419 1.9565-.6811 1.9565-1.7311 0-.6811-.2552-1.0785-.9357-1.3906-.9074-.3974-1.8998.0567-1.8714.9365 0 .3689.1701.596.5387.7662.2269.1136.2269.1136.0567.0852-.8222-.1703-1.0207-1.192-.3686-1.8447.7939-.7946 2.4669-.4541 3.034.6527.2268.4541.2551 1.3623.0567 1.9299-.4821 1.2487-1.8431 1.9014-3.2324 1.5325-.9357-.2555-1.3327-.5109-2.4669-1.6744-1.9848-2.0434-2.7504-2.4407-5.5858-2.8664l-.5388-.0851z" />
                                                    <g clipRule="evenodd" fillRule="evenodd">
                                                        <path d="m1.9575 1.22033c6.60659 8.03148 16.7858 20.51857 17.2962 21.19967.4253.5676.2552 1.1068-.4537 1.5041-.3969.2271-1.2192.4541-1.6162.4541-.4536 0-.9924-.227-1.361-.596-.2552-.2554-1.361-1.873-3.8562-5.761-1.8997-2.9799-3.51593-5.449-3.54428-5.4773-.11342-.0568-.11342-.0568 3.34578 6.13 2.1833 3.888 2.8922 5.2786 2.8922 5.4489 0 .3689-.1134.5676-.5671 1.0784-.7656.8514-1.1058 1.8163-1.361 3.8313-.2836 2.242-1.0491 3.8313-3.23241 6.5274-1.27596 1.5892-1.47444 1.873-1.78633 2.5258-.39697.7946-.51039 1.2487-.56709 2.2703-.05671 1.0785.0567 1.7596.3686 2.7813.28355.9081.59545 1.5041 1.36102 2.6677.65215 1.0216 1.04911 1.7879 1.04911 2.0717 0 .227.05671.227 1.0775 0 2.4385-.5676 4.4516-1.5325 5.5574-2.7245.6806-.7379.8507-1.1352.8507-2.1568 0-.6528-.0284-.7947-.1985-1.192-.2835-.6243-.8223-1.1352-1.9848-1.9298-1.5312-1.0501-2.1833-1.9015-2.3534-3.0367-.1418-.9649.0283-1.6176.879-3.4055.8789-1.8447 1.1058-2.611 1.2476-4.484.085-1.192.2268-1.6744.567-2.0434.3686-.3973.6805-.5392 1.5595-.6527 1.4461-.1987 2.3818-.5676 3.119-1.2771.6522-.596.9357-1.192.9641-2.0717l.0283-.6528-.3686-.3973c-1.3326-1.5325-19.79139-21.8524-19.876451-21.8524-.028355 0 .425321.539216.964051 1.22033zm8.7048 40.35607c.3119-.5393.1418-1.2204-.3686-1.5609-.48202-.3122-1.21923-.1703-1.21923.2554 0 .1135.05671.227.22683.2838.25519.1419.28355.2838.08507.596-.19848.3121-.19848.5959.05671.7946.39696.3122.93572.1419 1.21922-.3689z" />
                                                        <path d="m22.1475 26.6485c-.6805.1986-1.3326.9365-1.5311 1.6744-.1134.4541-.0567 1.2771.1418 1.5325.3119.3973.5954.5108 1.3893.5108 1.5595 0 2.8922-.6811 3.034-1.5041.1417-.6811-.4537-1.6176-1.276-2.0433-.4253-.2271-1.3043-.3122-1.758-.1703zm1.8147 1.419c.2269-.3406.1418-.7095-.2835-.9649-.7656-.4825-1.9281-.0852-1.9281.6527 0 .3689.5954.7662 1.1625.7662.3686 0 .879-.227 1.0491-.454z" />
                                                    </g>
                                                </g>
                                            </svg>
                                        </span>
                                        {/* PancakeSwap */}
                                    </a>
                                    {/*  2*/}

                                </li>
                            </ul>
                            <div className="modal__line">
                                <span className="line"></span>
                            </div>
                            <div className="modal__checkbox">
                                <input type="checkbox" name="" id="legal" onClick={() => setCheck(!check)} />
                                <label htmlFor="legal">
                                    I have read and agree to the <a href="/terms">Terms of Use</a>
                                </label>
                            </div>

                            <div className="modal__btn-wrap">
                                {/* <a href="https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0x7f4e04aa61b9a46403c1634e91bf31df3bc554cf" target="blank" className="header__navigation-link button --pointer">
                                swap coins
                            </a> */}
                                {!check ? (
                                    <a href="/#" className="header__navigation-link button --pointer">
                                        enter
                                    </a>
                                ) : (
                                    <a href="http://13.59.164.150:5100" className="header__navigation-link button --pointer" disabled>
                                        enter
                                    </a>
                                )}



                            </div>

                        </div>

                    </div>

                </div>
                <div className="photo">
                    <img className="photo__bg-no-light" src={img} alt="" />
                    <video autoPlay muted loop id="myVideo" style={{ width: '100%', height: '100%' }}>
                        <source src={video} type="video/mp4" />
                    </video>
                    <div className="help-container">
                        <div className="bg">

                            <a href="#" target="blank" className="hover-container">

                            </a>
                            <div className="hover-img">

                            </div>
                        </div>

                        <div className="bg">

                            <a href="#" target="blank" className="hover-container">

                            </a>
                            <div className="hover-img">

                            </div>
                        </div>

                        <div className="bg">
                            <a href="#" target="blank" className="hover-container">

                            </a>
                            <div className="hover-img">

                            </div>
                        </div>

                        <div className="bg">
                            <a href="https://www.coingecko.com/en/coins/wsb-sh" target="blank" className="hover-link ">
                                {/* coingecko */}
                            </a>
                        </div>
                        <div className="bg">
                            <a href="https://t.me/wsbsh" target="blank" className="hover-link ">
                                {/* telegram */}
                            </a>
                        </div>
                        <div className="bg">
                            <a href="https://twitter.com/wsb_sh?s=21" target="blank" className="hover-link ">
                                {/* twitter */}
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Modal